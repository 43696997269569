import accordion from "./accordion";
import accordioncontent from "./accordioncontent";
import accordionheader from "./accordionheader";
import accordionpanel from "./accordionpanel";
import autocomplete from "./autocomplete";
import avatar from "./avatar";
import badge from "./badge";
import badgedirective from "./badgedirective";
import breadcrumb from "./breadcrumb";
import button from "./button";
import buttongroup from "./buttongroup";
import checkbox from "./checkbox";
import chip from "./chip";
import confirmdialog from "./confirmdialog";
import confirmpopup from "./confirmpopup";
import datatable from "./datatable";
import dataview from "./dataview";
import datepicker from "./datepicker";
import dialog from "./dialog";
import divider from "./divider";
import fileupload from "./fileupload";
import floatlabel from "./floatlabel";
import global from "./global";
import iconfield from "./iconfield";
import image from "./image";
import inputgroup from "./inputgroup";
import inputgroupaddon from "./inputgroupaddon";
import inputmask from "./inputmask";
import inputnumber from "./inputnumber";
import inputtext from "./inputtext";
import menu from "./menu";
import multiselect from "./multiselect";
import paginator from "./paginator";
import panel from "./panel";
import panelmenu from "./panelmenu";
import password from "./password";
import popover from "./popover";
import progressbar from "./progressbar";
import progressspinner from "./progressspinner";
import radiobutton from "./radiobutton";
import select from "./select";
import selectbutton from "./selectbutton";
import skeleton from "./skeleton";
import slider from "./slider";
import splitbutton from "./splitbutton";
import tab from "./tab";
import tablist from "./tablist";
import tabmenu from "./tabmenu";
import tabpanel from "./tabpanel";
import tabpanels from "./tabpanels";
import tabs from "./tabs";
import tabview from "./tabview";
import tag from "./tag";
import textarea from "./textarea";
import tieredmenu from "./tieredmenu";
import toast from "./toast";
import togglebutton from "./togglebutton";
import toggleswitch from "./toggleswitch";
import tooltip from "./tooltip";
import tree from "./tree";

export default {
  global,
  directives: {
    badge: badgedirective,
    tooltip,
  },

  //forms
  autocomplete,
  select,
  dropdown: select,
  inputnumber,
  inputtext,
  datepicker,
  calendar: datepicker,
  checkbox,
  radiobutton,
  toggleswitch,
  inputswitch: toggleswitch,
  selectbutton,
  slider,
  multiselect,
  togglebutton,
  inputgroup,
  inputgroupaddon,
  inputmask,
  textarea,
  password,
  iconfield,
  floatlabel,

  //buttons
  button,
  buttongroup,
  splitbutton,

  //data
  paginator,
  datatable,
  tree,
  dataview,

  //panels
  accordion,
  accordionpanel,
  accordionheader,
  accordioncontent,
  panel,
  tabview,
  divider,
  tab,
  tabs,
  tablist,
  tabpanels,
  tabpanel,

  //file
  fileupload,

  //menu
  breadcrumb,
  panelmenu,
  tabmenu,
  menu,
  tieredmenu,

  //overlays
  dialog,
  popover,
  sidebar: popover,
  confirmpopup,
  confirmdialog,

  //messages
  toast,

  //media
  image,

  //misc
  avatar,
  badge,
  tag,
  chip,
  progressbar,
  skeleton,
  progressspinner,
};
