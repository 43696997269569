<template>
  <header
    class="flex items-center justify-center py-4 border-b border-surface-200 dark:border-surface-700 bg-surface-0 dark:bg-surface-900 text-dark-blue-2 dark:text-surface-200 transition-all duration-300"
  >
    <div class="w-11/12 sm:ml-16 flex flex-wrap items-center justify-end">
      <div class="flex flex-wrap items-center">
        <SplitButton
          v-if="!isMobile()"
          :label="copied ? 'Copiado' : this.store.todaysPassword"
          :icon="copied ? 'pi pi-check' : 'pi pi-copy'"
          :model="passwordOptions"
          outlined
          :severity="copied ? 'success' : 'secondary'"
          class="font-bold flex items-center"
          v-tooltip.bottom="copied ? 'Senha copiada' : 'Copiar senha do dia'"
          @click="copyToClipboard('today')"
        />
        <SplitButton
          v-else
          :label="copied ? 'Copiado' : this.store.todaysPassword"
          :model="passwordOptions"
          outlined
          :severity="copied ? 'success' : 'secondary'"
          class="font-bold flex items-center"
          v-tooltip.bottom="copied ? 'Senha copiada' : 'Copiar senha do dia'"
          @click="copyToClipboard('today')"
        />
      </div>
      <Button
        type="button"
        text
        rounded
        raised
        aria-label="Toggle Dark Mode"
        class="w-8 h-8 mx-5"
        :severity="isDarkTheme ? 'secondary' : 'warn'"
        :icon="!isDarkTheme ? 'pi pi-sun' : 'pi pi-moon'"
        @click="toggleDarkMode"
      >
      </Button>
      <div
        class="flex items-center cursor-pointer"
        @click="toggle"
        aria-haspopup="true"
        aria-controls="overlay_menu"
      >
        <Avatar
          shape="circle"
          class="flex items-center mr-3 text-primary-950 dark:text-surface-200 w-9 h-9 hover:outline outline-offset-2 outline-1 outline-primary-500/50 font-bold"
          :label="initials"
          :image="newImage"
        />
        <h2 class="text-xs text-dark-blue dark:text-surface-200 font-bold">
          {{ firstName }}
        </h2>
      </div>
    </div>
  </header>
  <Toast position="bottom-right" group="br" />
  <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
    <template #submenulabel="{ item }">
      <span>{{ item.label }}</span>
    </template>
    <template #item="{ item }">
      <li>
        <a :href="item.route" class="flex items-center cursor-pointer p-2">
          <i :class="item.icon"></i>
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </li>
    </template>
  </Menu>
  <Dialog
    v-model:visible="logoutDialog"
    class="w-9/12 sm:w-1/2 lg:w-1/3 xl:w-1/4"
    header="Sair"
    :modal="true"
  >
    <div class="confirmation-content flex">
      <i
        class="pi pi-exclamation-triangle mr-5 text-orange-600"
        style="font-size: 2rem"
      />
      <span>Deseja realmente sair?</span>
    </div>
    <template #footer>
      <Button
        label="Não"
        icon="pi pi-times"
        class="text-red-500"
        text
        @click="logoutDialog = false"
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        text
        @click="
          () => {
            logout();
          }
        "
      />
    </template>
  </Dialog>
</template>

<script>
import { useStore } from "@/store";
import { clearLocalStorageExcept } from "@/utils/storageUtils";
// import axios from "@/axios-config"; - Axios não está sendo importado para evitar erros com o Pinia.

export default {
  data() {
    return {
      userName: "",
      firstName: "",
      initials: "",
      userId: null,
      newImage: null,
      copied: false,
      passwordText: null,
      pathLogin: false,
      logoutDialog: false,
      isDarkTheme: false,
      items: [
        {
          separator: true,
        },
        {
          label: "Perfil",
          icon: "pi pi-user",
          command: () => {
            this.$router.push({
              name: "userProfile",
              params: { id: this.userId },
            });
          },
        },
        {
          separator: true,
        },
        {
          label: "Sair",
          icon: "pi pi-sign-out",
          command: () => {
            this.logoutDialog = true;
          },
        },
      ],
      passwordOptions: [
        {
          label: "Senha do dia",
          icon: "pi pi-sun",
          command: () => {
            this.copyToClipboard("today");
          },
        },
        {
          label: "Senha da semana",
          icon: "pi pi-calendar",
          command: () => {
            this.copyToClipboard("week");
          },
        },
      ],
    };
  },
  computed: {
    store() {
      return useStore();
    },
  },
  mounted() {
    this.pathLogin = !!localStorage.getItem("backoffice_token");
    if (this.pathLogin) {
      this.getUserInfo();
      this.getTodaysPassword();
      this.getInitials();
    }
    this.userId = localStorage.getItem("backoffice_userId");
    const darkMode = localStorage.getItem("darkMode") === "true";
    if (darkMode) {
      document.querySelector("html").classList.add("my-app-dark");
    }
    this.isDarkTheme = darkMode;
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    toggleDarkMode() {
      const element = document.querySelector("html");
      element.classList.toggle("my-app-dark");
      this.isDarkTheme = element.classList.contains("my-app-dark");
      localStorage.setItem("darkMode", this.isDarkTheme);
    },
    logout() {
      clearLocalStorageExcept();
      this.$router.push("/login");
    },
    isMobile() {
      return window.innerWidth <= 640;
    },
    getUserInfo() {
      const fullName = localStorage.getItem("backoffice_userName");
      if (fullName) {
        this.userName = fullName;
        this.firstName = this.userName.split(" ")[0];
      }
    },
    getInitials() {
      if (this.store.profileImage) {
        this.newImage = this.$urlBackend + this.store.profileImage;
        return;
      }
      if (!this.userName) this.initials = "US";
      const parts = this.userName.trim().split(" ");
      if (parts.length === 1) {
        this.initials = parts[0].substring(0, 2).toUpperCase();
      } else {
        this.initials =
          parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase();
      }
    },
    async getTodaysPassword() {
      if (!this.store.todaysPassword) {
        try {
          const token = localStorage.getItem("backoffice_token");
          const response = await this.$axios.get(
            `${this.$urlBackend}/api/v1/supportPassword`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.store.handleSupportPassword(response.data);
        } catch (error) {
          if (error.code == "ERR_NETWORK") {
            this.store.handleMessages(
              false,
              "Falha de conexão. Verifique a conexão com o servidor do backend e tente novamente.",
              "",
              "Erro de conexão"
            );
          } else if (error.response) {
            this.store.handleMessages(
              true,
              "",
              error.response.data,
              "Erro ao obter a senha do dia"
            );
          } else {
            this.store.handleMessages(true, "", error, "Erro inesperado");
          }
          this.store.errorControl(true);
          return;
        }
      }
    },
    async getWeekPassword() {
      try {
        const token = localStorage.getItem("backoffice_token");
        const response = await this.$axios.get(
          `${this.$urlBackend}/api/v1/supportWeekPassword`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.store.handleSupportWeekPassword(response.data);
      } catch (error) {
        if (error.code == "ERR_NETWORK") {
          this.store.handleMessages(
            false,
            "Falha de conexão. Verifique a conexão com o servidor do backend e tente novamente.",
            "",
            "Erro de conexão"
          );
        } else if (error.response) {
          this.store.handleMessages(
            true,
            "",
            error.response.data,
            "Erro ao obter as senhas da semana"
          );
        } else {
          this.store.handleMessages(true, "", error, "Erro inesperado");
        }
        this.store.errorControl(true);
        return;
      }
    },
    async copyToClipboard(option) {
      let textToCopy;
      if (option === "week") {
        await this.getWeekPassword();
        textToCopy = this.store.weekPassword;
      } else {
        textToCopy = this.store.todaysPassword;
      }
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      this.copied = true;
      textarea.id = "clipboard-textarea";
      textarea.classList.add("hidden");
      document.body.removeChild(textarea);
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
};
</script>
