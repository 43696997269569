<template>
  <aside
    id="sidebar"
    class="fixed top-0 left-0 bottom-0 z-40 h-screen overflow-hidden overflow-x-hidden transition-all duration-300"
    :class="{
      absolute: !store.displayed && isMobile(),
      'w-16': !store.displayed || isMobile(),
      'w-64': store.displayed && !isMobile(),
    }"
    aria-label="Sidebar"
  >
    <div
      class="flex flex-col h-full overflow-hidden box-border transition-all duration-300"
      :class="[
        {
          'bg-surface-0 dark:bg-surface-900':
            !isMobile() || (store.displayed && isMobile()),
        },
      ]"
    >
      <!-- Header -->
      <div
        class="flex items-center px-2 py-[19px] h-[69px] border-b border-surface-200 gradient-border-2 dark:border-surface-700 transition-all duration-300"
        :class="{
          'sm:justify-between ': store.displayed,
          'border-none justify-center': isMobile() && store.displayed,
        }"
      >
        <Button
          type="button"
          :text="!isMobile()"
          icon="pi pi-align-left"
          class="text-primary-400 dark:text-surface-400 ml-[5px]"
          @click="toggleSidebar"
          aria-haspopup="true"
          aria-controls="overlay_menu"
        />

        <a
          href="#"
          class="items-center"
          :class="{
            flex: store.displayed,
            hidden: !store.displayed || isMobile(),
          }"
        >
          <img
            v-if="store.displayed && !isMobile()"
            src="@/assets/images/logo-dark.png"
            class="me-3 sm:block mt-2 w-24 dark:opacity-50"
            alt="Opus127 Logo"
          />
        </a>
      </div>

      <!-- Menu Section -->
      <div
        class="flex p-3 ml-1 transition-opacity duration-300"
        :class="{ hidden: !store.displayed && isMobile() }"
      >
        <h2
          class="font-bold text-surface-600 dark:text-surface-300 text-xs uppercase"
        >
          Menu
        </h2>
      </div>

      <div
        class="flex flex-col px-3 py-2"
        :class="{
          'justify-center': !store.displayed || isMobile(),
          hidden: !store.displayed && isMobile(),
        }"
      >
        <ul class="w-full">
          <!-- Dashboard -->
          <SideBarItem
            to="/"
            label="Dashboard"
            tooltip="Dashboard"
            :active="$route.path === '/'"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 22 22 "
              >
                <path
                  d="M8.3942 19.0406V16.2294C8.39418 15.5144 8.97711 14.9334 9.69934 14.9286H12.3449C13.0706 14.9286 13.6589 15.511 13.6589 16.2294V16.2294V19.0493C13.6587 19.6564 14.1481 20.1526 14.7612 20.1668H16.5249C18.2831 20.1668 19.7084 18.7558 19.7084 17.0152V17.0152V9.01818C19.699 8.33342 19.3743 7.6904 18.8266 7.27211L12.7947 2.46169C11.7379 1.6241 10.2358 1.6241 9.17905 2.46169L3.1736 7.28084C2.62382 7.69743 2.29852 8.34152 2.29175 9.02691V17.0152C2.29175 18.7558 3.71705 20.1668 5.47525 20.1668H7.23896C7.86724 20.1668 8.37656 19.6626 8.37656 19.0406V19.0406"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>

          <!-- Analises -->
          <SideBarItem
            to="/analises"
            label="Análises"
            tooltip="Análises"
            :active="$route.path.startsWith('/analises')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            icon="pi pi-chart-line"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getTelemetry') &&
                !hasPermission('getDevPoints') &&
                !hasPermission('getImplPoints') &&
                !hasPermission('getSupPoints') &&
                // !hasPermission('getFinance') &&
                // !hasPermission('getSales') &&
                // !hasPermission('getManagement') &&
                !hasPermission('getOperations') &&
                !hasPermission('getClientRevenue'),
            }"
          />

          <li
            v-if="!isMobile() || store.displayed"
            class="border-t border-surface-200 dark:border-surface-700 my-2"
          ></li>
          <!-- Company -->
          <SideBarItem
            to="/empresas/0"
            label="Empresas"
            tooltip="Empresas"
            :active="$route.path.startsWith('/empresas')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getCompanies') &&
                !hasPermission('getCompanyGroups') &&
                !hasPermission('getSegment'),
            }"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99544 14.287V11.9614"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.6741 3.88574C17.2233 3.88574 18.4699 5.14158 18.4699 6.69074V9.84408C16.2149 11.1641 13.2358 11.9616 9.99075 11.9616C6.74575 11.9616 3.77575 11.1641 1.52075 9.84408V6.68158C1.52075 5.13241 2.77659 3.88574 4.32575 3.88574H15.6741Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.2038 3.88184V3.54634C13.2038 2.42801 12.2963 1.52051 11.1779 1.52051H8.81294C7.69461 1.52051 6.78711 2.42801 6.78711 3.54634V3.88184"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.54321 13.1924L1.71646 15.4923C1.8338 17.0424 3.12538 18.2405 4.67913 18.2405H15.3115C16.8653 18.2405 18.1569 17.0424 18.2742 15.4923L18.4475 13.1924"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>

          <!-- Users -->
          <SideBarItem
            to="/usuarios/0"
            label="Usuários"
            tooltip="Usuários"
            :active="$route.path.startsWith('/usuarios')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getUser') &&
                !hasPermission('getDepartment') &&
                !hasPermission('getRoles'),
            }"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                >
                <path
                  d="M16.397 9.98859C17.6757 9.80892 18.6602 8.71258 18.663 7.38433C18.663 6.07533 17.7087 4.99 16.4575 4.78467"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.0845 13.0625C19.3229 13.2477 20.1873 13.6812 20.1873 14.575C20.1873 15.1901 19.7803 15.5897 19.1221 15.8409"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.896 13.4414C7.94984 13.4414 5.43359 13.8878 5.43359 15.6707C5.43359 17.4527 7.93426 17.912 10.896 17.912C13.8422 17.912 16.3575 17.4702 16.3575 15.6863C16.3575 13.9025 13.8578 13.4414 10.896 13.4414Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.896 10.897C12.8293 10.897 14.3968 9.33042 14.3968 7.39626C14.3968 5.46301 12.8293 3.89551 10.896 3.89551C8.96279 3.89551 7.39529 5.46301 7.39529 7.39626C7.38796 9.32309 8.94354 10.8906 10.8704 10.897H10.896Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.39442 9.98859C4.11475 9.80892 3.13117 8.71258 3.12842 7.38433C3.12842 6.07533 4.08267 4.99 5.33392 4.78467"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.70684 13.0625C2.46842 13.2477 1.604 13.6812 1.604 14.575C1.604 15.1901 2.011 15.5897 2.66917 15.8409"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
          <!-- Product -->
          <SideBarItem
            to="/produtos/0"
            label="Produtos"
            tooltip="Produtos"
            :active="$route.path.startsWith('/produtos')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getProduct') &&
                !hasPermission('getOpusBkp') &&
                !hasPermission('getFluxusMobile'),
            }"
          >
            <template #icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 w-5 h-5"
              >
                <path
                  d="M4 7.59003L12 4L20 7.59003L12 11.18L4 7.59003Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 16.41L12 20L20 16.41"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20 7.59003V16.42"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 7.59003V16.42"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 11.45V19.73"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>

          <li
            v-if="!isMobile() || store.displayed"
            class="border-t border-surface-200 dark:border-surface-700 my-2"
          ></li>
          <SideBarItem
            to="/painel-controle/0"
            label="Painel de Controle"
            tooltip="Painel de Controle"
            :active="$route.path.startsWith('/painel-controle')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50':
                !hasPermission('getVersion') &&
                !hasPermission('getSlide') &&
                !hasPermission('getRevenue'),
            }"
          >
            <template #icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="flex-shrink-0 w-5 h-5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Stroke 1"
                  d="M10.3301 16.593H4.02942"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Stroke 3"
                  d="M13.1405 6.90048H19.4412"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Stroke 5"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.72629 6.84638C8.72629 5.55072 7.66813 4.50012 6.36314 4.50012C5.05816 4.50012 4 5.55072 4 6.84638C4 8.14203 5.05816 9.19263 6.36314 9.19263C7.66813 9.19263 8.72629 8.14203 8.72629 6.84638Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Stroke 7"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 16.5539C20 15.2582 18.9426 14.2076 17.6376 14.2076C16.3318 14.2076 15.2737 15.2582 15.2737 16.5539C15.2737 17.8496 16.3318 18.9001 17.6376 18.9001C18.9426 18.9001 20 17.8496 20 16.5539Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
          <li
            v-if="!isMobile() || store.displayed"
            class="border-t border-surface-200 dark:border-surface-700 my-2"
          ></li>
          <SideBarItem
            to="/chatbot"
            label="Chatbot"
            tooltip="Chatbot"
            :active="$route.path.startsWith('/chatbot')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :icon="'pi pi-comment'"
          />
        </ul>
      </div>

      <div
        class="flex h-full items-end px-3 py-2 transition-all duration-300"
        :class="{
          'justify-center': isMobile(),
          hidden: !store.displayed && isMobile(),
        }"
      >
        <ul class="w-full flex flex-col">
          <SideBarItem
            to="/configuracoes/0"
            label="Configurações"
            tooltip="Configurações"
            :active="$route.path.startsWith('/configuracoes')"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            :class="{
              'pointer-events-none opacity-50': !hasPermission('getSettings'),
            }"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.0728 6.98846L18.5022 5.99837C18.0195 5.16061 16.9498 4.8716 16.1109 5.3523V5.3523C15.7116 5.58754 15.2351 5.65428 14.7865 5.53781C14.3379 5.42133 13.9541 5.1312 13.7196 4.7314C13.5688 4.47728 13.4878 4.18784 13.4847 3.89235V3.89235C13.4983 3.4186 13.3196 2.95951 12.9892 2.61967C12.6589 2.27983 12.205 2.08818 11.7311 2.08838H10.5816C10.1172 2.08837 9.67206 2.2734 9.34452 2.60251C9.01698 2.93162 8.8341 3.37769 8.83633 3.842V3.842C8.82257 4.80066 8.04146 5.57055 7.08271 5.57045C6.78721 5.56738 6.49777 5.48634 6.24365 5.33552V5.33552C5.40474 4.85482 4.33504 5.14383 3.85235 5.98159L3.23984 6.98846C2.75773 7.82517 3.0428 8.8942 3.87752 9.37976V9.37976C4.4201 9.69301 4.75434 10.2719 4.75434 10.8984C4.75434 11.525 4.4201 12.1039 3.87752 12.4171V12.4171C3.04386 12.8994 2.75848 13.9659 3.23984 14.8V14.8L3.81879 15.7985C4.04495 16.2066 4.42441 16.5077 4.8732 16.6353C5.32199 16.7628 5.80311 16.7063 6.21009 16.4782V16.4782C6.61018 16.2447 7.08694 16.1807 7.53442 16.3005C7.98189 16.4202 8.36299 16.7138 8.593 17.1158C8.74383 17.37 8.82487 17.6594 8.82794 17.9549V17.9549C8.82794 18.9234 9.61306 19.7085 10.5816 19.7085H11.7311C12.6963 19.7085 13.4801 18.9285 13.4847 17.9633V17.9633C13.4824 17.4975 13.6665 17.0502 13.9958 16.7208C14.3252 16.3915 14.7725 16.2074 15.2383 16.2097C15.5331 16.2175 15.8213 16.2983 16.0774 16.4446V16.4446C16.9141 16.9267 17.9831 16.6416 18.4687 15.8069V15.8069L19.0728 14.8C19.3066 14.3987 19.3708 13.9207 19.2511 13.4718C19.1314 13.023 18.8377 12.6404 18.4351 12.4087V12.4087C18.0325 12.1771 17.7388 11.7945 17.6191 11.3457C17.4994 10.8968 17.5636 10.4188 17.7974 10.0174C17.9495 9.75195 18.1696 9.53182 18.4351 9.37976V9.37976C19.2648 8.89446 19.5492 7.83168 19.0728 6.99685V6.99685V6.98846Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <ellipse
                  class=""
                  :class="{
                    'stroke-white': $route.path.startsWith('/configuracoes'),
                  }"
                  cx="11.1606"
                  cy="10.8984"
                  rx="2.41648"
                  ry="2.41648"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
          <SideBarItem
            to="#"
            label="Sair"
            tooltip="Sair"
            :active="false"
            :is-mobile="isMobile()"
            :displayed="store.displayed"
            @click="logoutDialog = true"
          >
            <template #icon>
              <svg
                class="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7647 6.77369V5.91844C13.7647 4.05303 12.2522 2.54053 10.3868 2.54053H5.91802C4.05352 2.54053 2.54102 4.05303 2.54102 5.91844V16.1209C2.54102 17.9864 4.05352 19.4989 5.91802 19.4989H10.3959C12.2558 19.4989 13.7647 17.9909 13.7647 16.131V15.2666"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.9922 11.0194H8.95459"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.3079 8.34766L19.9919 11.0197L17.3079 13.6927"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </SideBarItem>
        </ul>
      </div>
      <!-- Version Info -->
      <span
        class="flex justify-center font-bold text-surface-400 text-xs mt-2 mb-10 transition-opacity duration-300"
        :class="{ hidden: !store.displayed && isMobile() }"
      >
        v{{ version }}
      </span>
    </div>
  </aside>
  <Dialog
    v-model:visible="logoutDialog"
    class="w-9/12 sm:w-1/2 lg:w-1/3 xl:w-1/4"
    header="Sair"
    :modal="true"
  >
    <div class="confirmation-content flex">
      <i class="pi pi-exclamation-triangle mr-5 text-orange-600 text-3xl" />
      <span>Deseja realmente sair?</span>
    </div>
    <template #footer>
      <Button
        label="Não"
        icon="pi pi-times"
        class="text-red-500"
        text
        @click="logoutDialog = false"
      />
      <Button label="Sim" icon="pi pi-check" text @click="logout" />
    </template>
  </Dialog>
</template>

<script>
import { version } from "@/config/version";
import { useStore } from "@/store";
import { clearLocalStorageExcept } from "@/utils/storageUtils";
import SideBarItem from "./SideBarItem.vue";

export default {
  components: {
    SideBarItem,
  },
  data() {
    return {
      logoutDialog: false,
      pathLogin: false,
      version: version,
    };
  },

  emits: ["showSidebar"],
  computed: {
    store() {
      return useStore();
    },
  },
  mounted() {
    this.pathLogin = !!localStorage.getItem("backoffice_token");
    if (this.pathLogin == true) {
      this.$emit("showSidebar");
    }
  },
  methods: {
    toggleSidebar() {
      this.store.toggleSidebarVisibility();
      this.$emit("showSidebar");
    },
    isMobile() {
      return window.innerWidth < 640;
    },
    hasPermission(permission) {
      return this.$permissions.hasPermission(permission);
    },
    logout() {
      clearLocalStorageExcept();
      this.$router.push("/login");
    },
  },
};
</script>
