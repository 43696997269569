const keysToExclude = ["darkMode", "backoffice_displayed"]; // Add the keys you want to preserve here

export function clearLocalStorageExcept() {
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    if (!keysToExclude.includes(key)) {
      localStorage.removeItem(key);
    }
  });
}
